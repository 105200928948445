<template>
  <div id="app" :style="backg">
    <div class="teambanner">
      <img :src="backg">
    </div>
    <div v-if="couponListId" class="couponDetail">
      <div>
        <div class="left">
          <div>{{ coupoList.couponName }}</div>
          <div class="price">
            <span>￥</span><span>{{ coupoList.usedMoney }}</span><span>优惠券</span>
          </div>
          <div>有效期:{{ coupoList.usedRemind }}</div>
          <img
            src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-09-25/16/yuelvhuiy2LqLVhxxJ1601022447.png"
            alt=""
          >
          <!-- <img v-else src="../images/coupons/noCAnGet1.png" alt=""> -->
        </div>
        <div class="right">
          <!-- <div>立即领取</div> -->
          <div>已领取</div>
          <!-- <div v-else='noCanGet'>立即使用</div> -->
        </div>
      </div>
    </div>
    <!-- <div class="list_box" :style="clo"> -->
    <!-- {{titlelist}} -->
    <vanTabs v-if="closetitle" v-model="titlelistnoe" nav-left @click="gettitle">
      <vanTab v-for="(ele,index) in titlelist" id="bad" :key="index" :title="ele.name">
        <div class="list_box" :style="clo">
          <div
            v-for="(item) in shopList"
            :key="item.goodId"
            class="list_item"
            @click="goGoodsDetail(item)"
          >
            <div class="itemimg"><img :src="item.goodCover" alt=""></div>
            <div>
              <p class="item_goodname">{{ item.goodName }}</p>
              <p class="item_pricebox">
                <span class="item_pricevip">¥{{ item.goodVipPrice }}</span>
                <span class="item_price">¥{{ item.goodPrice }}</span>
              </p>
            </div>
          </div>
        </div>
      </vanTab>
    </vanTabs>
    <div v-if="closetitle1" class="list_box" :style="clo">
      <div
        v-for="(item) in shopList"
        :key="item.goodId"
        class="list_item"
        @click="goGoodsDetail(item)"
      >
        <div class="itemimg"><img :src="item.goodCover" alt=""></div>
        <div>
          <p class="item_goodname">{{ item.goodName }}</p>
          <p class="item_pricebox">
            <span class="item_pricevip">¥{{ item.goodVipPrice }}</span>
            <span class="item_price">¥{{ item.goodPrice }}</span>
          </p>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <!--提示-->
    <div id="toast" class="toast" />
  </div>
</template>

<script>
/* eslint-disable eqeqeq */
import Vue from 'vue'
import { Tab, Tabs, Toast } from 'vant'

Vue.use(Toast).use(Tab).use(Tabs)

import {
  ajaxNewActivityList,
  ajaxGetCouponList,
  ajaxgetActivityCategoryInfo
} from '@/services/couponPage'

export default {
  name: 'ActivityList',
  data() {
    return {
      shopList: '',
      teamBanner: '',
      coupoList: '',
      couponListId: '',
      backg: '',
      clo: {},
      titlelist: [],
      titlelistnoe: '',
      closetitle: false,
      closetitle1: false,

      oid: null // id
    }
  },
  mounted() {
    const { oid } = this.$route.query
    this.oid = oid
    this.getList(oid)
    this.getListCoupon()
    this.ajaxGetActivityCategoryInfo()
  },
  methods: {
    async ajaxGetActivityCategoryInfo() {
      const data = {
        id: this.oid
      }
      const res = await ajaxgetActivityCategoryInfo(data)
      this.backg = res.data.activityInfo.backGround.url
      this.clo = { background: res.data.activityInfo.backColor }
      if (res.data.categoryInfo.length > 1) {
        this.closetitle = true
        this.closetitle1 = false
        this.titlelist = res.data.categoryInfo
        this.titlelistnoe = this.titlelist[0].name
      } else {
        this.closetitle = false
        this.closetitle1 = true
        this.titlelistnoe = res.data.activityInfo.activityName
        this.titlelist = [{
          name: this.titlelistnoe,
          id: this.oid
        }]
        //  this.getList()
      }
    },
    async gettitle(idx, title) {
      const data = {
        id: this.oid,
        categoryId: this.titlelist[idx].id
      }
      const res = await ajaxNewActivityList(data)
      this.shopList = res.data[0].goodsInfo
    },
    getListCoupon(id) {
      const _self = this
      const _data = {
        uid: localStorage.getItem('uid'),
        couponActivityId: id
      }
      ajaxGetCouponList(_data).then((res) => {
        if (Number(res.code) === 200) {
          _self.coupoList = res.data.list[0]
        } else {
          // Toast(res.msg);
        }
      })
    },
    getList(id) {
      const _self = this
      const _data = {
        uid: localStorage.getItem('uid'),
        id: id,
        categoryId: 0,
        page: 1,
        pageSize: 10
      }

      ajaxNewActivityList(_data).then((res) => {
        if (Number(res.code) === 200) {
          _self.couponListId = res.couponListId
          _self.shopList = res.data[0].goodsInfo
          _self.teamBanner = res.data[0].teamBanner
          _self.getListCoupon(res.couponListId)
          // if(_self.couponListId.length<2){
          // _self.gettitle(0)
          // }
        } else {
          Toast(res.msg)
        }
      })
    },
    goGoodsDetail({ goodId, skuId }) {
      const { codeNumber, room_id } = this.$route.query
      const route = {
        path: '/goodsDetailZY',
        query: {
          goodsId: goodId,
          skuid: skuId,
          type: 'ordinary',
          codeNumber: codeNumber,
          room_id: room_id
        }
      }
      this.$router.push(
        route
      )
    }
  }
}
</script>

<style lang="less" scoped>
.teambanner {
  width: 100%;
  /* height: 290px; */
}

.couponDetail {
  width: 100%;
  height: 20vh;
  background: url("../../assets/images/quanbg.png");
  background-size: 100% 100%;

  > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 14px;

    .left {
      width: 65%;
      height: 70%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      background: #E33F44;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;

      > img {
        position: absolute;
        width: 25%;
        height: 62%;
        top: 0;
        left: 0;
      }
    }

    .right {
      width: 25%;
      height: 70%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: cornsilk;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;

      div {
        background: #E33F44;
        padding: 10%;
        border-radius: 10px;
      }
    }
  }
}

.teambanner img {
  width: 100%;
  height: 100%;
  display: block;
}

.list_box {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.list_item {
  text-align: left;
  width: 45%;
  height: 230px;
  background: #fff;
  border-radius: 5px;
  margin-top: 10px;
  padding: 5px;
}

.itemimg {
  width: 100%;
  height: 150px;
}

.itemimg img {
  width: 100%;
  height: 100%;
  display: block;
}

.item_price {
  font-size: 12px;
  text-decoration: line-through;
  color: gray;
}

.item_pricevip {
  font-size: 15px;
  color: red;
  margin-right: .4rem;
}

.item_goodname {
  font-size: 15px;
  width: 100%;
  //text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: bold;
  line-height: 1.2;
}

.item_pricebox {
  line-height: 35px;
  padding-left: 10px;
}
</style>
